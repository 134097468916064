export const ACTIONS = {
  SET_LOADING: "SET_LOADING",
  SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
  IS_METAMASK_PRESENT: "IS_METAMASK_PRESENT",
  SET_DISCORD_NAME: "SET_DISCORD_NAME",
  SET_TWITTER_NAME: "SET_TWITTER_NAME",
  IS_FOLLOWING_TWITTER: "IS_FOLLOWING_TWITTER",
  IS_DISCORD_MEMBER: "IS_DISCORD_MEMBER",
  OWNED_NFT_COUNT: "OWNED_NFT_COUNT",
  HAS_RARE: "HAS_RARE",
  CONNECTION_SUCCESS: "CONNECTION_SUCCESS",
};
