import React from "react";
import "./index.css";

const MobileWarning = () => {
  return (
    <div className="mobile_warning">
      Please user your computer to verify yourself
    </div>
  );
};

export default MobileWarning;
